<template>
  <div class="WhatsAppConnectToTwilio">
    <base-alert type="info" class="twilio-description">
        <p class="mb-2">
          If you want to get notifications through SMS you first need to connect your <a href="http://twilio.com" _target="blank">Twilio account</a>.
          Twilio is a cloud communications platform, which we will use as a provider in order to send notifications.</p>

        <p class="mb-2">
          We are working on simplifying this process, so that in the future you won't need a separate provider account.
        </p>
    </base-alert>
    <twilio-form @connect="$emit('connect')"
                :user = "user"/>
  </div>
</template>

<script>
import TwilioForm from '@/components/Forms/ConnectToTwilioForm.vue'

export default {
  components: {
    TwilioForm
  },

  props: {
    user: {
      required: true,
      type: Object
    }
  }
}
</script>
