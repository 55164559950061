export default {
  /**
   * Add phone number.
   *
   * @param monitor_id
   * @param phone_number
   * @returns Promise
   */
  addPhoneNumber (monitor_id, phone_number) {
    return axios.post(`/monitors/${monitor_id}/notifications/sms/`, {
      phone_number
    })
  },

  testSms (phone_number) {
    return axios.post(`/monitors/notifications/test-sms/`, {
      phone_number
    })
  },

  generalAddPhoneNumber (phone_number) {
    return axios.post(`/monitors/general/notifications/sms/`, {
      phone_number
    })
  }
}
