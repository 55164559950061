<template>
  <div class="SmsPhoneNumbers">
    <div v-if="general">
      <div class="description">
        You can specify up to 5 phone numbers to which we will send SMS notifications
        in case of something went wrong with your monitors.
      </div>
      <base-alert type="error" v-if="generalError && generalHyperlink">
        You have reached the phone numbers limit on monitor: <a :href=generalHyperlink>{{generalError}}</a>
      </base-alert>
      <base-alert type="error" v-else-if="generalError">
        {{generalError}}
      </base-alert>
        <div class="phone-number"
             v-for="(channel, index) in userGeneralNotificationChannels"
             :key="index">

          <div class="remove"
               @click.prevent="deleteGeneralChannel(channel)">
            <icon-trash width="18"
                        height="18" />
          </div>

          <div class="text">
            {{ channel.item_value }}
          </div>
          </div>
      <new-number :general="general"
                  @add="loadGeneralNotificationChannels"
                  @error="smsAddError"/>
    </div>
    <div v-else>
      <div class="description">
        You can specify up to 5 phone numbers to which we will send SMS notifications
        in case of something went wrong with
        <monitor-label :monitor="monitor"
                       :show-favicon="false" />.
        <br>
        You have a total of 3 tests per day.
        <br>
        <div v-bind:class="{ 'text-danger': user.tests_per_day < 1 }">Remaining tests {{user.tests_per_day}}.</div>
      </div>

      <div class="phone-numbers">
        <div class="phone-number"
             v-for="(channel, index) in channels"
             :key="index">

          <div class="remove"
               @click.prevent="removePhoneNumber(channel)">
            <icon-trash width="18"
                        height="18" />
          </div>

          <div class="text">
            {{ channel.item_value }}
          </div>

          <base-button v-if="user.tests_per_day > 0"
                       class="ml-auto"
                       color="primary"
                       :loading="sendingSms"
                       @click="testSmsPhoneNumber(channel.item_value)">Test sms</base-button>
        </div>
        <base-alert v-if="sendingSmsError" class="mt-4"
                    type="error">{{sendingSmsError}}</base-alert>
      </div>

      <new-number v-if="channels.length < 5"
                  :monitor="monitor"
                  :general="general"
                  @add="onNumberAdd" />
    </div>
  </div>
</template>

<script>
import MonitorLabel from '@/components/Monitor/MonitorLabel.vue'
import NewNumber from './SmsPhoneNumbersNew.vue'

import notificationChannelsApi from '@/api/monitorNotifications/notificationChannelsApi.js'
import smsApi from '@/api/monitorNotifications/smsApi'
import usersApi from '../../../../api/usersApi'

export default {
  components: {
    MonitorLabel,
    NewNumber
  },

  props: {
    monitor: {
      required: false,
      type: Object
    },
    general: {
      required: false,
      default: false,
      type: Boolean
    }
  },

  data () {
    return {
      channels: [],
      saving: false,
      sendingSms: false,
      sendingSmsError: false,
      userGeneralNotificationChannels: null,
      generalError: false,
      generalHyperlink: false
    }
  },

  created () {
    if (this.general) {
      this.loadGeneralNotificationChannels()
    }
  },

  methods: {
    onNumberAdd () {
      this.$emit('update')
    },

    async removePhoneNumber (channel) {
      await notificationChannelsApi.delete(channel.id)
      this.$emit('update')
    },

    async deleteGeneralChannel (channel) {
      await notificationChannelsApi.generalDelete(channel.id)
      await this.loadGeneralNotificationChannels()
    },

    async loadGeneralNotificationChannels () {
      try {
        this.userGeneralNotificationChannels = await usersApi.getGeneralChannels('twilio_sms')
      } catch (e) {
        this.generalError = 'Something went wrong'
      }
    },

    async testSmsPhoneNumber (phoneNumber) {
      this.sendingSms = true
      this.sendingSmsError = false
      try {
        await smsApi.testSms(phoneNumber)
      } catch (error) {
        this.sendingSmsError = error.response.data.message ?? 'We could not send the sms.'
      }
      await this.refreshCurrentUser()
      this.sendingSms = false
    },

    async smsAddError (value) {
      this.generalError = value.errorMonitor
      this.generalHyperlink = value.hyperlink
    },

    ...mapActions('authentication', [
      'refreshCurrentUser'
    ])
  },

  computed: {
    ...mapState('authentication', ['user'])
  },

  watch: {
    monitor: {
      handler: function () {
        if (!this.general) {
          this.channels = this.monitor.notification_channels
            .filter(channel => channel.via === 'twilio_sms')
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .SmsPhoneNumbers {
    max-width: 550px;

    .description {
      margin-bottom: 10px;
      color: #777;
      line-height: 1.5;

      .text-danger {
        color: #ED4F32;
      }
    }

    .phone-numbers {
      max-width: 405px;
      margin-bottom: 10px;
    }

    .phone-number {
      display: flex;
      align-items: center;

      .remove {
        cursor: pointer;
        padding: 10px 10px 10px 0;

        &:hover /deep/ svg g {
          transition: 0.2s all ease;
          fill: map-get($colors, purple-1) !important;
        }
      }

      .text {
        margin-bottom: 3px;
      }
    }
  }
</style>
