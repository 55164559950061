<template>
  <div class="Sms">
    <base-button class="update-settings" v-if="user.connected_to_twilio" @click="setUpdateCredentialsToTrue()">Update Twilio Credentials</base-button>

    <div class="content">
      <connect-to-twilio v-if="!user.connected_to_twilio || update_credentials"
                         @connect="refreshCurrentUser; setUpdateCredentialsToFalse()"
                         :user = "user"/>

      <phone-numbers v-if="user.connected_to_twilio && !update_credentials"
                     :monitor="monitor"
                     :general="general"
                     @update="$emit('update')" />
    </div>
  </div>
</template>

<script>
import ConnectToTwilio from './SmsConnectToTwilio.vue'
import PhoneNumbers from './SmsPhoneNumbers.vue'

export default {
  components: {
    ConnectToTwilio,
    PhoneNumbers
  },

  props: {
    monitor: {
      required: false,
      type: Object
    },
    general: {
      required: false,
      default: false,
      type: Boolean
    }
  },

  data () {
    return {
      update_credentials: false
    }
  },

  methods: {
    setUpdateCredentialsToTrue () {
      this.update_credentials = true
    },
    setUpdateCredentialsToFalse () {
      this.update_credentials = false
    },
    ...mapActions('authentication', [
      'refreshCurrentUser'
    ])
  },

  computed: {
    ...mapState('authentication', [
      'user'
    ])
  }
}
</script>

<style lang="scss" scoped>
  .Sms {
    position: relative;

    .update-settings {
      position: absolute;
      right: -10px;
      top: -6px;

      cursor: pointer;
      padding: 10px;
      border-radius: 4px;
      transition: 0.2s all ease;

      font-weight: 700;
      font-size: 11px;
      line-height: 1;
      text-transform: uppercase;

      &:hover {
        background-color: #f6f5f7;
      }
    }

    .content {
      max-width: 550px;
    }

    .description {
      margin-bottom: 10px;
      color: #777;
      line-height: 1.5;
    }

    .twilio-description {
      margin: 0;

      p:first-child {
        margin-top: 0;
      }

      .base-text-box {
        width: 380px;
      }
    }
  }
</style>
